import React, { ReactElement, useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { placeHolders, testIds } from "@timhortons/common/src/utils/formHelpers";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import TextField from "@timhortons/common/src/components/atoms/textField";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { AuditType } from "@timhortons/common/src/modules/rev/components/dashboards";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import useDebounce from "@timhortons/web/src/utils/useDebounce";
import { GetStoreListByCodeOrNamePayload } from "@timhortons/common/src/models/rev/selfCalibration";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { Roles } from "@timhortons/common/src/constants/roles";
import MapleLogo from "@timhortons/common/src/assets/images/mapleLogo";
import { ComparisonStoresAdminModel } from "models/profile";
import LpStoreSearchResults from "@timhortons/common/src/modules/lp/organisms/lpAuthModal/lpStoreSearchResults";
import { DatePicker } from "@timhortons/web/src/components/atoms/datepicker/datepicker";

interface IProps {
  handleAuth: Function;
  translate?: any;
  children?: React.ReactNode;
  modalVisible: boolean;
  setModalState: () => void;
  handleCancelButton?: () => void;
  isIndividualLogin: boolean;
  isAppeal?: boolean;
  currentButton?: AuditType;
  closeIconVisible?: boolean;
  buttonText?: string;
  widthTextField?: number;
  handleDisabled?: boolean;
  getStoreListByStoreCodeOrName?: (value: GetStoreListByCodeOrNamePayload) => void;
  user?: IUserDetails;
  getStoresLists?: ComparisonStoresAdminModel;
  error?: string;
  getStorelistByCodeIsEnd?: boolean;
  getMoreSToreListLoading?: boolean;
  resetStoreListState?: () => void;
  setConsolidatedDates?:Function;
  consolidatedDate?:any;
}

export default function LpAuthModal(props: IProps): ReactElement {
  const {
    handleAuth,
    translate,
    setModalState,
    isIndividualLogin,
    isAppeal = false,
    currentButton,
    closeIconVisible = true,
    buttonText,
    widthTextField,
    getStoreListByStoreCodeOrName,
    getStoresLists,
    user,
    handleCancelButton,
    resetStoreListState,
    setConsolidatedDates,
    consolidatedDate
  } = props;
  const [value, setValue] = useState("");
  const [keyword, setKeyword] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [openStoreModal, setOpenStoreModal] = useState(false);
  const empStoreNameDebouncedValue = useDebounce(value, 300);
  const empStoreNameDebouncedKeyWord = useDebounce(keyword, 200);
  const [selectedStoreValue, setSelectedStoreValue] = useState(null);
 

  const [page, setPage] = useState(0);
  useEffect(() => {
    if (empStoreNameDebouncedValue !== null && empStoreNameDebouncedValue?.length >= 1) {
      if (!user?.roles.includes(Roles.StoreAccess) && getStoreListByStoreCodeOrName && !isClicked) {
        setOpenStoreModal(true);
        getStoreListByStoreCodeOrName({
          keyword: empStoreNameDebouncedKeyWord,
          pageNo: page,
          pageSize: 10,
          empId: user?.empId,
          role: user?.roles[0]
        });
      }
    }
  }, [empStoreNameDebouncedValue, page]);

  const handleAuthSubmit = (values: { empId: string }): void => {
    if (currentButton) {
      handleAuth(values.empId, currentButton);
    } else {
      handleAuth(values.empId);
    }

    setModalState();
  };

  const handleChange = (e: string) => {
    setKeyword(e);
    setValue(e);
    setIsClicked(false);
    if (e.length === 1) {
      resetStoreListState && resetStoreListState();
    }
  };
  const returnSelectedStore = (storeDetails: { code: number; name: string }) => {
    storeDetails && storeDetails?.code && setValue(String(storeDetails.code));
  };
  const handelClose = () => {
    setModalState();
    handleCancelButton && handleCancelButton();
  };

  return (
    <>
      {closeIconVisible && (
        <View style={styles.iconContainer}>
          <Icon
            name={IconNames.close}
            onPress={handelClose}
            customStyle={styles.iconStyles}
            size={20}
          />
        </View>
      )}

      <View style={styles.contentContainer}>
        <View style={styles.imageStyle}>
          <MapleLogo height={100} width={100} fillColor={colorPallete.red1} />
        </View>
        <Text
          fontWeight={FontWeight.Bold}
          testId={testIds.testAudit.authModalText}
          textSize={TextSize.ExtraRegular}
          textStyle={styles.textStyle}
        >
          {isIndividualLogin ? translate("enterLocation") : translate("authHeader")}
        </Text>

        <View>
          <TextField
            handleChange={(e: any) => handleChange(e)}
            // handleBlur={() => formikProps.setFieldTouched("empId")}

            value={value.toString()}
            inputStyle={
              widthTextField ? [styles.inputStyle, { width: widthTextField }] : styles.inputStyle
            }
            placeholder={
              isIndividualLogin
                ? translate(placeHolders.manageStoreSearch)
                : translate(placeHolders.manageStoreSearch)
            }
            name="empId"
            id="empId"
            handleKeyPress={(e: any) => {
              if (e.key === "Enter") {
                handleAuthSubmit({ empId: e.target.value });
              }
            }}
          />
          {/* {PlatformUtils.isWeb() ? ( */}
          <DatePicker
            // disablePastDates
            dateContainerStyle={styles.startDatePickerContainer}
            textStyles={styles.datePickerText}
            iconStyles={[styles.datePickerIcon, { marginTop: 4 }]}
            fontWeight={FontWeight.Regular}
            textSize={TextSize.ExtraRegular}
            placeholderText={translate("Select Audit Date")}
            iconSize={12}
            parentCallBack={(val: Date) => {
              setConsolidatedDates({ ...consolidatedDate, startDate: val });
            }}
            minDate={new Date("2024-10-01 10:34:23")}
          />
          {/* ) : (
            <CalendarComponent
              // disablePastDates/
              dateContainerStyle={styles.startDatePickerContainer}
              textStyles={styles.datePickerText}
              iconStyles={styles.datePickerIcon}
              selectRange={false}
              parentCallBack={(val: Date) => {
                setConsolidatedDates({ ...consolidatedDate, startDate: val });
              }}
              placeholderText={translate("startDate")}
              iconSize={12}
              fontWeight={FontWeight.Regular}
              textSize={TextSize.ExtraRegular}
            />
          )} */}
          <Button
            type={ButtonType.Primary}
            buttonStyles={styles.buttonStyles}
            disabled={
              String(selectedStoreValue) === String(value) &&
              String(value)?.length == 6 &&
              String(value)?.charAt(0) === "8"
                ? false
                : true
            }
            onPress={() => handleAuthSubmit({ empId: value })}
          >
            <Text
              textStyle={styles.btnTextStyles}
              fontWeight={FontWeight.SemiBold}
              textSize={TextSize.Regular}
              testId={testIds.testAudit.authModalButtonText}
            >
              {(isAppeal && translate("startAppeal")) ||
                (buttonText ? buttonText : null) ||
                translate("startAudit")}
            </Text>
          </Button>
        </View>
      </View>
      {empStoreNameDebouncedValue?.length >= 1 && openStoreModal && (
        <LpStoreSearchResults
          translate={translate}
          setIsClicked={setIsClicked}
          returnSelectedStore={returnSelectedStore}
          setOpenStoreModal={setOpenStoreModal}
          getStoreListByStoreCodeOrName={getStoreListByStoreCodeOrName}
          getStoresLists={getStoresLists}
          user={user}
          setTextFeildValue={setValue}
          keyword={empStoreNameDebouncedKeyWord}
          setKeyword={setKeyword}
          setSelectedStoreValue={setSelectedStoreValue}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    // marginHorizontal: 48,
    width: 472,
    height: 380,
    flexDirection: "column",
    // justifyContent: "space-between",
    alignItems: "center"
  },
  iconContainer: {
    alignItems: "flex-end",
    margin: 24,
    marginBottom: 0
  },
  imageStyle: {
    marginVertical: 24,
    alignSelf: "center"
  },
  iconStyles: {
    // fontWeight: "300",
    color: colorPallete.grey2
    // fontSize: 20,
    // lineHeight: 30
  },
  textStyle: {
    marginBottom: 16,
    paddingHorizontal: 4,
    color: colorPallete.black3
  },
  btnTextStyles: {
    // fontSize: 16,
    paddingVertical: PlatformUtils.isWeb() ? 12 : 8,
    // paddingHorizontal: PlatformUtils.isWeb() ? 60 : 30,
    color: colorPallete.white3
  },
  buttonStyles: {
    borderRadius: 8,
    alignSelf: "center",
    marginVertical: 24,
    // padding: 8
    width: 200
  },
  inputStyle: {
    width: 300,
    borderRadius: 4,
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorPallete.grey6,
    alignSelf: "center"
  },
  modalStyle: {},
  datePickerText: {
    color: colorPallete.black4
  },
  datePickerIcon: {
    color: colorPallete.grey2
  },
  startDatePickerContainer: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    marginTop:20,
    backgroundColor: colorPallete.white1
  }
});
