import {
  LMSDeleteVideoModel,
  LMSGetVideoUploadLink,
  LMSUploadVideoModel
} from "@timhortons/common/src/models/manageLms/lmsModule";

const api_version = `/rev/v1`;
const userApiVersion = `/user/v1`;
const storeApiVersion = `/store/v1`;
const resourceApiVersion = `resources/v1`;
const iamVersion = `/iam/v1`;
const logsVersion = `/logs/v1`;
export const productionSheetVersion = `/production-sheet/v1`;
export const lp_version = `/lp/v1`;
export const lp_version2 = `/v2`;
export const manageLmsVersion = `/lms/v1`;
const userLmsVersion = `/ask-tell/v1`;
const calendarVersion = `/calendar/v1`;
const manageNewsApiVersion = "news/v1";
const manageAskTell = `/ask-tell/v1`;
const manageNotification = `/notification/v1`;
export const lmsReports = `/lms/v1`;

export const profileEndPoints = {
  userProfile: () => `/profile`,
  uploadFile: () => `/upload`,
  getStoreTeamDetails: (storeId: number) => `${iamVersion}/store/${storeId}/team-details`,
  getIndividualTeamDetails: () => `${iamVersion}/user/by-role`,
  getStoresTabIndividualTeamDetails: () => `${storeApiVersion}/store/all-individual-access`,
  getStoreProfile: (storeId: string) => `${storeApiVersion}/store/${storeId}/profile`,
  getIndividualProfile: (userId: string) => `${iamVersion}/employee/${userId}/profile`,
  getDetailedUserInfo: (employeeId: string) =>
    `${iamVersion}/employee/${employeeId}/detailed-profile`,
  getUserProfileDetails: (employeeId: string) =>
    `${userApiVersion}/employee/${employeeId}/profile-details`,
  getAllCountries: () => `${storeApiVersion}/country`,
  getAllRoles: () => `${iamVersion}/role`,
  postCreateStore: () => `${storeApiVersion}/store`,
  postStoreImage: () => `${storeApiVersion}/store/upload/profile-picture`,
  postUserImage: () => `${iamVersion}/user/profile-picture`,
  postCreateUser: () => `${iamVersion}/user`,
  getAllChannels: () => `${storeApiVersion}/channel/all`,
  getStoreSpecifics: () => `${storeApiVersion}/store-specifics/all`,
  getAllCities: (countryCode: number) => `${storeApiVersion}/city/country/${countryCode}`,
  getAllAreaManager: (role: string) => `${iamVersion}/user/role/${role}/countries`,
  getEmployeeDocument: (empId: string, docType: string, docSide: string) =>
    `${iamVersion}/employee/${empId}/document/${docType}/${docSide}`,
  getEmployeeCertificate: (empId: string, docType: string) =>
    `${iamVersion}/employee/${empId}/certificate/${docType}`,
  postEmployeeDocument: (empId: string) => `${iamVersion}/employee/${empId}/document`,
  getEmployeeCountDocument: (empId: string) =>
    `${iamVersion}/employee/${empId}/assets/document/count`,
  getEmployeeCountCertificate: (empId: string) =>
    `${iamVersion}/employee/${empId}/assets/certificate/count`,
  getUserTableDetails: () => `${iamVersion}/user/manage`,
  getStoreTableDetails: () => `${storeApiVersion}/store/manage`,
  getStoreByCode: (storeCode: number) => `${storeApiVersion}/store/by-code/${storeCode}`,
  getAllStoresList: () => `${storeApiVersion}/store/assign-stores`,
  postAssignedStores: (empId: string) => `${iamVersion}/user/employee/${empId}/assign-store`,
  getEditStoreDetails: (storeId: number) => `${storeApiVersion}/store/${storeId}/detailed-profile`,
  getGccCountries: () => `${storeApiVersion}/country/gcc`
};

export const actionPlanEndPoints = {
  getActionPlanCategoryTableData: (calibrationId: number, actionPlanId: number) =>
    `${api_version}/self-calibration/${calibrationId}/action-plan/${actionPlanId}/categories`,
  getActionPlanTableData: () => `${api_version}/action-plans`,
  updateActionPlanStatus: (actionPlanId: number) => `${api_version}/action-plan/${actionPlanId}`,
  getActionPlanAccordianData: (data: {
    categoryId: number;
    actionPlanId: number;
    calibrationId: number;
  }) =>
    `rev/v1/self-calibration/${data.calibrationId}/action-plan/${data.actionPlanId}/category/${data.categoryId}/opportunities`,
  // `https://demo6885916.mockable.io/thag/opportunityList`
  postActionSteps: () => `${api_version}/action-item`,
  //  `https://demo7951933.mockable.io/thag/actionPlans`
  getActionPlanHeaderData: (actionPlanId: number) => `${api_version}/action-plan/${actionPlanId}`,
  getOwnerData: (storeId: string) => `${iamVersion}/user/action-plan-owners/${storeId}`
};

//
export const selfCalibrationEndPoints = {
  createTestAudit: () => `${api_version}/test-audit`,
  createStandardAudit: () => `${api_version}/standard-audit`,
  fetchCategory: (selfCalibrationId: number) =>
    `${api_version}/self-calibration/${selfCalibrationId}/categories`,
  subCategory: (storeCode: number, calibrationId: number, categoryId: number) =>
    `${api_version}/store/${storeCode}/self-calibration/${calibrationId}/category/${categoryId}/sub-categories`,
  postAnswer: () => `${api_version}/calibration-record`,
  observationData: (calibrationId: number, questionId: number) =>
    `${api_version}/self-calibration/${calibrationId}/question/${questionId}/observations`,
  postCommentData: (subCategory: number, calibrationId: number) =>
    `${api_version}/sub-category/${subCategory}/self-calibration/${calibrationId}/feedback`,
  postObservationSelection: () => `${api_version}/observation-answer`,
  submitAudit: (calibrationId: number) => `${api_version}/audits/${calibrationId}`,
  prepareReport: () => `${api_version}/self-calibration/prepare/report`,
  fetchTippingPoints: (questionId: number) =>
    `${api_version}/question/${questionId}/tipping-points`,
  sosAvgTime: (calibrationId: number, subCategoryId: number) =>
    `${api_version}/self-calibration/${calibrationId}/sub-category/${subCategoryId}/sos-timer`,
  previousAuditStore: (storeCode: number) => `${api_version}/stores/${storeCode}/audits`,
  previousAuditIndividual: (empId: number) => `${api_version}/auditors/${empId}/audits`,
  deleteAudit: (calibrationId: number) => `${api_version}/audits/${calibrationId}`,
  getCommentData: (subCategory: number, calibrationId: number) =>
    `${api_version}/sub-category/${subCategory}/self-calibration/${calibrationId}/feedback`,
  getExternalMeta: () => `${api_version}/audits/external/categories/subcategories`,
  postExternalAuditData: () => `${api_version}/audits/external`,
  postExternalAuditAsset: (calibrationId: number) =>
    `${api_version}/audits/${calibrationId}/report/asset/`,
  storeAccessValidation: () => `${iamVersion}/user/validate-store-access`,
  individualAccessValidation: () => `${iamVersion}/user/validate-individual-access`,
  getStoreListByCodeOrName: (empId: string) =>
    `${storeApiVersion}/store/employee/${empId}/store/search`
};

export const reportsEndPoints = {
  downloadReport: (reportId: string) =>
    `${api_version}/download/self-calibration/report/${reportId}`,
  fetchReportsData: (calibrationId: number) =>
    `${api_version}/self-calibration/report/${calibrationId}`,
  fetchOpportunityData: (calibrationId: number) => `${api_version}/report/${calibrationId}`,
  fetchReportTableDataStore: (storeId: number) => `${api_version}/reports/store/${storeId}`,
  fetchReportTableDataIndividual: (auditorId: string) =>
    `${api_version}/reports/auditor/${auditorId}`,
  postAcnowledgementData: () => `${api_version}/self-calibration/report/acknowledge`,
  fetchStoreAccessSummaryData: (storeId: number) =>
    `${api_version}/report/summary/store/${storeId}`,
  fetchStoreAccessOpportunityData: (storeId: number) =>
    `${api_version}/report/summary/store/${storeId}/opportunities`,
  fetchIndividualReportsSummaryData: () => `${api_version}/individual-report-summary`,
  getAllCountries: () => `${storeApiVersion}/country/`,
  getAllAreaManagers: (role: string) => `${iamVersion}/user/role/${role}/countries`,
  getAllChannels: () => `${storeApiVersion}/channel/country/by-codes`,
  getAllStores: () => `${storeApiVersion}/store/country/by-codes`,
  fetchAcknowledgementData: (storeCode: string) =>
    `${iamVersion}/user/store-incharges/${storeCode}`,
  getAllAreaManagersCM: (employeeId: string) =>
    `${iamVersion}/user/country-manager/${employeeId}/area-managers`,
  getAllChannelsCM: () => `${storeApiVersion}/channel/users/employee-ids`,
  getAllStoresCM: () => `${storeApiVersion}/store/users/employee-ids`,
  generateReportBuilder: (reportType: string, allSelected: boolean, pageNum: number) =>
    `${api_version}/report-builder?pageNo=${pageNum}&pageSize=5&reportType=${reportType}&allSelected=${allSelected}`,
  genReportBuilderAdminComparisonChannels: (
    reportType: string,
    countryList: any,
    pageNum: number
  ) =>
    `${api_version}/report-builder?pageNo=${pageNum}&pageSize=5&reportType=${reportType}&countryCodes=${countryList}`,
  getComparisonCountryManager: () => `${iamVersion}/user/country-managers`,
  getComparisonAreaManager: () => `${iamVersion}/user/area-managers`,
  getComparisonStoresAdmin: () => `${storeApiVersion}/store/all`,
  getHighLowChannelsAdmin: () => `${storeApiVersion}/channel/all-paginated`,
  getCountryManagerCountryDetails: () => `${iamVersion}/country-manager/country-details`,
  getKPIdata: () => `${api_version}/categories`
};

export const appealsEndPoints = {
  getAppealAccordionData: (appealId: number, categoryId: number) =>
    `${api_version}/appeal/${appealId}/category/${categoryId}/appeal-items`,
  createNewAppeal: () => `${api_version}/appeal`,
  getAppealsCategoryData: (appealId: number) => `${api_version}/appeal/${appealId}`,
  postAppealItem: (employeeID: string) => `${api_version}/appeal-item?employeeId=${employeeID}`,
  setAppealDecision: () => `${api_version}/appeal-item/auditor`
};

export const appealEndPoints = {
  // fetchAppealFilteredAPI: () => `${api_version}/appeal-items`, // older version of appeals individual access
  fetchAppealFilteredAPI: (employeeId: number) =>
    `${api_version}/appeal-items/${employeeId}/search`,
  getAppealList: (storeId: number, status: string) =>
    `${api_version}/appeals/store/${storeId}/${status}`,

  getAppealsCountIndividual: (appealerId: string) => `${api_version}/appeal-items/${appealerId}`,
  getAppealsCountStore: (storeCode: string) => `${api_version}/appeal-items/store/${storeCode}`
};

export const resourcesEndPoints = {
  getContentList: () => `${resourceApiVersion}/category`,
  getPublishCategories: () => `${resourceApiVersion}/publish/category`,
  getSubCategoriesList: (categoryId: number) =>
    `${resourceApiVersion}/category/${categoryId}/sub-category`,
  getPublishedSubCategoriesList: (categoryId: number) =>
    `${resourceApiVersion}/publish/category/${categoryId}/sub-category`,
  getTopicList: (categoryId: number) =>
    `${resourceApiVersion}/sub-category/${categoryId}/topic-list`,
  getPublishedTopicList: (categoryId: number) =>
    `${resourceApiVersion}/publish/sub-category/${categoryId}/topic-list`,
  getTopicContent: (subCategoryId: string) => `${resourceApiVersion}/sub-category/${subCategoryId}`,
  getPublishedTopicContent: (subCategoryId: string) =>
    `${resourceApiVersion}/publish/sub-category/${subCategoryId}`,
  postSubCategory: () => `${resourceApiVersion}/sub-category`,
  putSubCategory: (subCategoryId: string) => `${resourceApiVersion}/sub-category/${subCategoryId}`,
  postTopic: () => `${resourceApiVersion}/topic`,
  putTopic: (topicId: string) => `${resourceApiVersion}/topic/${topicId}`,
  deleteTopic: (topicId: string) => `${resourceApiVersion}/topic/${topicId}`,
  postCreateNewContent: () => `${resourceApiVersion}/category`,
  updateContent: (subCatId: string, topicId: string) =>
    `${resourceApiVersion}/${subCatId}/${topicId}/update-content`,
  deleteCategory: (catId: string) => `${resourceApiVersion}/category/${catId}`,
  categoryImageUpload: () => `${resourceApiVersion}/category/generate-public-url`,
  searchCategoryWithFilter: (param: any) =>
    `${resourceApiVersion}/category/text-search?searchText=${param.searchText}&categoryFilter=${param.categoryFilter}`,
  searchCategory: (param: any) =>
    `${resourceApiVersion}/category/text-search?searchText=${param.searchText}`,
  publishCategory: (categoryId: string) => `${resourceApiVersion}/publish?categoryId=${categoryId}`,
  getMarkDownContent: (categoryId: number) => `${resourceApiVersion}/sub-category/${categoryId}`,
  putCategoryTitle: (categoryId: string) => `${resourceApiVersion}/category/${categoryId}`,
  deleteSubCategory: (subCategoryId: string) =>
    `${resourceApiVersion}/sub-category/${subCategoryId}`
};
export const userAuthenticationEndPoints = {
  fetchUserAuthentication: () => `/iam/v1/authenticate`,
  storeAuthentication: () => `/store/v1/authenticate`,
  fetchMasterModules: () => `/iam/v1/role/features`
};
export const logsManagerReviewEndPoints = {
  getManagerReviewTableData: () => `${logsVersion}/manager-review`,
  updateMRApprovalLogs: () => `${logsVersion}/manager-review`
};
export const timeTempLogsEndPoints = {
  // Time Temperature Tab
  getTimeTempCategories: (logActionName: string) =>
    `${logsVersion}/action/${logActionName}/category`,
  getQuestionsUnderCategory: (categoryId: string) =>
    `${logsVersion}/category/${categoryId}/questions`,
  getReceivingLogsCategories: (categoryId: string) =>
    `${logsVersion}/category/${categoryId}/subcategory`,
  getReceivingLogsSubCategories: (categoryId: string) =>
    `${logsVersion}/receiving-logs/sub-category/${categoryId}`,
  getReceivingLogsItems: (itemId: string) => `${logsVersion}/receiving-logs/items/${itemId}`,
  getProductsCategories: () => `${logsVersion}/product`,
  recordTemperature: () => `${logsVersion}/item-temperature`,
  getProductTempItems: (categoryId: string) => `${logsVersion}/product/${categoryId}/item`,
  getLogShift: () => `${logsVersion}/shifts`,
  getPreviousShift: () => `${logsVersion}/previous-shifts`,
  getEquipmentTempItems: (categoryId: string) => `${logsVersion}/category/${categoryId}/item`,
  logsAuthentication: () => `${iamVersion}/user/validate-store-access`,
  logsUpdateAuthentication: () => `${logsVersion}/shifts/employee`
};
export const cleaningLogsEndPoints = {
  getEmployeeDropDown: () => `${iamVersion}/users/storeCode`,
  postRestRoomItems: () => `${logsVersion}/answer`,
  getRestRoomQuestions: (id: string) => `${logsVersion}/product/${id}/questions`
};
export const stationReadLogsEndPoints = {
  getSubCategory: () => `${logsVersion}/product`,
  postStationReadinessItems: () => `${logsVersion}/answer`,
  getSubCategoryItems: (subCategoryId: string) =>
    `${logsVersion}/product/${subCategoryId}/questions`,
  stationReadAuthenticate: () => `${logsVersion}/station-readiness/authenticate`
};
export const managerWalkthroughLogsEndPoints = {
  getManagerWalkthroughCategories: () => `${logsVersion}/manager-walk-through-category/categories`,
  postManagerWalkthrough: () => `${logsVersion}/answer`
};

export const managerWalkthroughActionsEndPoints = {
  getActionManagerWalkthroughActions: (subCategoryId: string) =>
    `${logsVersion}/category/${subCategoryId}/question-actions`,
  updateManagerWalkthroughActions: () => `${logsVersion}/answer/actions/resolve`
};

export const timeTempActionsEndPoints = {
  getProductTempActions: (id: string) => `${logsVersion}/product/${id}/item-actions`,
  getEquipmentTempActions: (id: string) => `${logsVersion}/category/${id}/item-actions`,
  updateProductActions: () => `${logsVersion}/item-temperature/actions/resolve`
};

export const cleaningActionsEndPoints = {
  weeklyActions: (categoryId: string) => `${logsVersion}/category/${categoryId}/question-actions`,
  restRoomActions: () => `${logsVersion}/restroom-checklist/actions/resolve`,
  getDailyCleaningItems: (id: string) => `${logsVersion}/product/${id}/question-actions`,
  dailyCheckListActions: () => `${logsVersion}/answer/actions/resolve`,
  getRestRoomCleaningActions: (id: string) => `${logsVersion}/product/${id}/question-actions`
};
export const logsSummaryEndPoints = {
  getCategoriesMetaData: () => `${logsVersion}/action/category`,
  getLogsSummaryData: () => `${logsVersion}/summary`
};
export const individualActionsTableEndPoints = {
  getIndividualLogActionsTable: () => `${logsVersion}/individual-access/actions?`,
  getIndividualLogsDashboardTable: () => `${logsVersion}/individual-access?`,
  getActionsStoreSpecificTable: () => `${logsVersion}/manager-review/individual-access/all-actions?`
};
export const productionSheetEndPoints = {
  getAllCategoryAndProducts: (storeCode: number, stationCode: string) =>
    `${productionSheetVersion}/main-menu/${storeCode}/station/${stationCode}`,
  postCategoryAndProducts: () => `${productionSheetVersion}/main-menu`,
  getAllStations: () => `${productionSheetVersion}/station`,
  getStoreHeader: (storeCode: number) =>
    `${productionSheetVersion}/store/${storeCode}/production-sheet/header`,
  getStationProducts: (storeCode: number) =>
    `${productionSheetVersion}/store/${storeCode}/production-sheet/subcategories`,
  getProductItems: (storeCode: number, stationId: string) =>
    `${productionSheetVersion}/store/${storeCode}/station/${stationId}/production-sheet`,
  postProductionSheetData: (storeCode: number, stationId: string, productId: string) =>
    `${productionSheetVersion}/store/${storeCode}/station/${stationId}/product/${productId}/production-sheet`,
  getBakingStationCategories: (storeCode: number, stationId: string) =>
    `${productionSheetVersion}/store/${storeCode}/station/${stationId}/production-sheet/categories`,
  getManagerReviewData: (storeCode: number) =>
    `${productionSheetVersion}/store/${storeCode}/manager-review`,
  getAmPmEmployeeData: (storeCode: number) =>
    `${productionSheetVersion}/store/${storeCode}/am-pm-employee`,
  postAmPmEmployeeAuthData: (storeCode: number, stationCode: string) =>
    `${productionSheetVersion}/store/${storeCode}/station/${stationCode}/authenticate`,
  getOthersProducts: (storeCode: number, stationId: string) =>
    `${productionSheetVersion}/store/${storeCode}/station/${stationId}/production-sheet`,
  getOthersCategories: (storeCode: number, stationId: string) =>
    `${productionSheetVersion}/store/${storeCode}/station/${stationId}/production-sheet/categories`,
  updateManagerReviewItem: () => `${productionSheetVersion}/manager-review`,
  postOthersProductionSheetData: (storeCode: number, stationId: string, productId: string) =>
    `${productionSheetVersion}/store/${storeCode}/station/${stationId}/product/${productId}/production-sheet`,
  reports: {
    genReportBuilderComparision: (prodSheetType: string, countryCodes: any, allSelected: boolean) =>
      `${productionSheetVersion}/report-builder/generate-report/${prodSheetType}?reportType=COMPARISON&countryCodes=${countryCodes}&allSelected=${allSelected}`,
    genReportBuilderDetailed: (prodSheetType: string, countryCodes: any) =>
      `${productionSheetVersion}/report-builder/generate-report/${prodSheetType}?reportType=DETAILED&countryCodes=${countryCodes}`,
    getCategory: () => `${productionSheetVersion}/report-builder/category`,
    getProduct: () => `${productionSheetVersion}/report-builder/product`
  }
};
export const LPEndPoints = {
  audits: {
    lpIndividualAccessValidation: () => `${iamVersion}/user/validate-individual-access`,
    createLpStandardAudit: () => `${lp_version}${lp_version2}/standard-audit`,
    getAuditStoreListByCodeOrName: (empId: string) =>
      `${storeApiVersion}/store/employee/${empId}/store/search`,
    deleteLpAudit: (auditId: number) => `${lp_version}${lp_version2}/audits/${auditId}`,
    fetchPreviousAuditStore: (storeCode: number) => `${lp_version}${lp_version2}/auditors/${storeCode}/audits`,
    fetchLpCategory: (auditId: number) => `${lp_version}${lp_version2}/audit/${auditId}/categories`,
    fetchLpSubCategory: (storeCode: number, auditId: number, categoryId: number) =>
      `${lp_version}${lp_version2}/store/${storeCode}/audit/${auditId}/category/${categoryId}/sub-categories`,
    fetchTippingPoints: (questionId: number) =>
      `${lp_version}/question/${questionId}/tipping-points`,
    postLpAnswer: () => `${lp_version}${lp_version2}/calibration-record`,
    lpObservationData: (auditId: number, questionId: number) =>
      `${lp_version}${lp_version2}/audit/${auditId}/question/${questionId}/observations`,
    postObservationSelection: () => `${lp_version}${lp_version2}/observation-answer`,
    postLpCommentData: (subCategory: number, auditId: number, questionId: number) =>
      `${lp_version}${lp_version2}/sub-category/${subCategory}/audit/${auditId}/question/${questionId}/feedback`,
    getLpCommentData: (subCategory: number, auditId: number, questionId: number) =>
      `${lp_version}${lp_version2}/sub-category/${subCategory}/audit/${auditId}/question/${questionId}/feedback`
  },
  reports: {
    submitAudit: (auditId: number) => `${lp_version}${lp_version2}/audits/${auditId}`,
    prepareReport: () => `${lp_version}${lp_version2}/audit/prepare/report`,
    fetchReportsData: (auditId: number) => `${lp_version}${lp_version2}/audit/report/${auditId}`,
    fetchOpportunityData: (auditId: number) => `${lp_version}${lp_version2}/report/${auditId}`,
    fetchAcknowledgementData: (storeCode: string) =>
      `${iamVersion}/user/store-incharges/${storeCode}`,
    postAcknowledgementData: () => `${lp_version}${lp_version2}/audit/report/acknowledge`,
    fetchReportTableDataStore: (storeId: number) => `${lp_version}${lp_version2}/reports/store/${storeId}`,
    fetchReportTableDataIndividual: (auditorId: string) =>
      `${lp_version}${lp_version2}/reports/auditor/${auditorId}`,
    fetchLpStoreAccessSummaryData: (storeId: number) =>
      `${lp_version}/report/summary/store/${storeId}`,
    fetchLpIndividualReportsSummaryData: () => `${lp_version}/individual-report-summary`,
    fetchLpIndividualSummarySRTableData: () =>
      `${lp_version}/individual-report-summary/store-ranking`,
    fetchTotalAuditDetailsData: () => `${lp_version}/individual-report-summary/audit-details`,
    fetchLpStoreAccessOpportunityData: (storeId: number) =>
      `${lp_version}/report/summary/store/${storeId}/opportunities`,
    generateReportBuilder: (reportType: string, allSelected: boolean, pageNum: number) =>
      `${lp_version}/report-builder?pageNo=${pageNum}&pageSize=10&reportType=${reportType}&allSelected=${allSelected}`,
    genReportBuilderAdminComparisonChannels: (
      reportType: string,
      countryList: any,
      pageNum: number
    ) =>
      `${lp_version}/report-builder?pageNo=${pageNum}&pageSize=10&reportType=${reportType}&countryCodes=${countryList}`,
    getAllCountries: () => `${storeApiVersion}/country/`,
    getAllDetailedAreaManagers: (role: string) => `${iamVersion}/user/role/${role}/countries`,
    getAllDetailedAreaTrainer: (role: string) => `${iamVersion}/user/role/${role}/countries`,
    getAllAreaTrainer: () => `${iamVersion}/user/role/Area Trainer/countries`,
    getAllAreaManagers: () => `${iamVersion}/user/area-managers?`,
    getCountryManager: (role: string) => `${iamVersion}/user/role/${role}/countries`,
    getCountryManagerWoCountry: (role: string) => `${iamVersion}/user/role/${role}`,
    getAllOpsManagers: () => `${iamVersion}/user/ops-managers`,
    getAllAreaManagersCM: (employeeId: string) =>
      `${lp_version}/user/country-manager/${employeeId}/area-managers`,
    getAllChannels: () => `${storeApiVersion}/channel/country/by-codes`,
    getAllChannelsCM: () => `${storeApiVersion}/channel/users/employee-ids`,
    getAllStores: () => `${storeApiVersion}/store/country/by-codes`,
    getAllStoresCM: () => `${storeApiVersion}/store/users/employee-ids`,
    getClusterHeadOfOps: (role: string) => `${iamVersion}/user/role/${role}/countries`,
    getClusterHeadOfOpsWocountry: (role: string) => `${iamVersion}/user/role/${role}`,
    getAllLpAuditor: (role: string) => `${iamVersion}/user/role/${role}/countries`,
    getComparisonCountryManager: () => `${iamVersion}/user/country-managers`,
    getComparisonAreaManager: () => `${iamVersion}/user/area-managers`,
    getComparisonStoresAdmin: () => `${storeApiVersion}/store/all`,
    getHighLowChannelsAdmin: () => `${storeApiVersion}/channel/all-paginated`,
    getCountryManagerCountryDetails: () => `${iamVersion}/country-manager/country-details`,
    generateGradingReportBuilder: (allSelected: boolean, pageNum: number) =>
      `${lp_version}/report-builder/grading?pageNo=${pageNum}&pageSize=10&allSelected=${allSelected}`,
    getKPIdata: () => `${lp_version}/categories`,
    getBrandStandardTabledata: () =>
      `${lp_version}/report-builder/areas-of-improvement/opportunity`,
    getAreaOfImprovementReportBuilder: () =>
      `${lp_version}/report-builder/areas-of-improvement/observations`,
    downloadGeneratedReport: () => `${lp_version}/report-builder/download-report`,
    lpSummaryPDFDownload: () => `${lp_version}/individual-report-summary/report-download`,
    lpStoreSummaryPDFDownload: (storeCode: number) =>
      `${lp_version}/report/summary/store/${storeCode}/report-download`,
    lpAuditDownload: () => `${lp_version}/audit/report-download`,
    lpAuditListDownload: (empId: string) =>
      `${lp_version}/reports/auditor/${empId}/report-download`,
    lpStoreAuditListDownload: (storeCode: number) =>
      `${lp_version}/reports/store/${storeCode}/report-download`
  },
  appeals: {
    getLpAppealList: (storeId: number, status: string) =>
      `${lp_version}/appeals/store/${storeId}/${status}`,
    getLpAppealsCountIndividual: (appealerId: string) => `${lp_version}/appeal-items/${appealerId}`,
    getLpAppealsCountStore: (storeCode: string) => `${lp_version}/appeal-items/store/${storeCode}`,
    createLpNewAppeal: () => `${lp_version}/appeal`,
    getLpAppealsCategoryData: (appealId: number) => `${lp_version}/appeal/${appealId}`,
    setLpAppealDecision: () => `${api_version}/appeal-item/auditor`,
    lpStoreAccessValidation: () => `${iamVersion}/user/validate-store-access`,
    postLpAppealItem: (employeeID: string) => `${lp_version}/appeal-item?employeeId=${employeeID}`,
    getLpAppealAccordionData: (appealId: number, categoryId: number) =>
      `${lp_version}/appeal/${appealId}/category/${categoryId}/appeal-items`
  }
};

export const lpActionPlanEndPoints = {
  getLpActionPlanTableData: () => `${lp_version}/action-plans`,
  postLpActionSteps: () => `${lp_version}/action-item`,
  updateLpActionSteps: (actionPlanId: number, subCategoryId: number) =>
    `${lp_version}/sub-category/${subCategoryId}/action-plan/${actionPlanId}/edit`,
  updateLpActionPlanStatus: (actionPlanId: number) => `${lp_version}/action-plan/${actionPlanId}`,
  getLpActionPlanAccordianData: (data: {
    categoryId: number;
    actionPlanId: number;
    auditId: number;
  }) =>
    `lp/v1/audit/${data.auditId}/action-plan/${data.actionPlanId}/category/${data.categoryId}/opportunities`,
  getLpActionPlanHeaderData: (actionPlanId: number) => `${lp_version}/action-plan/${actionPlanId}`,
  getLpActionPlanCategoryTableData: (auditId: number, actionPlanId: number) =>
    `${lp_version}/audit/${auditId}/action-plan/${actionPlanId}/categories`,
  getLpOwnerData: (storeId: string) => `${iamVersion}/user/action-plan-owners/${storeId}`,
  getLpAuditorsForActionPlan: () => `${lp_version}/action-plan/auditors`,
  actionPlanDownload: () => `${lp_version}/action-plans/report-download`
};

export const lpAppealsEndPoints = {
  setLpAppealDecision: () => `${lp_version}/appeal-item/auditor`,
  fetchLpAppealFilteredAPI: (employeeId: number) =>
    `${lp_version}/appeal-items/${employeeId}/search`
};

export const manageLmsEndPoints = {
  postManageLmsModule: () => `${manageLmsVersion}/module`,
  getLMSAllModule: () => `${manageLmsVersion}/module`,
  getLMSModuleById: (moduleId: string) => `${manageLmsVersion}/module/${moduleId}`,
  postLmsCategory: () => `${manageLmsVersion}/category`,
  getAllCategories: () => `${manageLmsVersion}/category`,
  deleteCategory: (categoryId: string) => `${manageLmsVersion}/category/${categoryId}`,
  getCategoryById: (categoryId: string) => `${manageLmsVersion}/category/${categoryId}`,
  postTopic: () => `${manageLmsVersion}/topic`,
  getModuleCategories: (moduleId: string) => `${manageLmsVersion}/category/module/${moduleId}`,
  uploadLmsVideoLink: (obj: LMSGetVideoUploadLink) =>
    `${manageLmsVersion}/video/link?title=${obj.title}&videoType=${obj.videoType}&id=${obj.id}`,
  uploadLmsVideo: (obj: LMSUploadVideoModel) =>
    `${manageLmsVersion}/video?title=${obj.title}&duration=${obj.duration}&id=${obj.id}&videoType=${obj.videoType}&language=${obj.language}`,
  editLmsCategory: () => `${manageLmsVersion}/category`,
  deleteLmsTopic: (topicId: string) => `${manageLmsVersion}/topic/${topicId}`,
  deleteLmsModule: (moduleId: string) => `${manageLmsVersion}/module/${moduleId}`,
  getLmsTopicById: (categoryId: string) => `${manageLmsVersion}/topic/category/${categoryId}`,
  lmsProgressBar: (moduleId: string) => `${manageLmsVersion}/progress/module/${moduleId}`,
  getSummaryDetails: (moduleId: string) =>
    `${manageLmsVersion}/module/${moduleId}/summary?language=EN`,
  postMCQTicTacQuiz: () => `${manageLmsVersion}/mcqTttQuestions`,
  getCategoryDetailsAll: (categoryId: string) => `${manageLmsVersion}/category/${categoryId}/all`,
  editLmsTopic: () => `${manageLmsVersion}/topic`,
  postLmsSubtopic: () => `${manageLmsVersion}/sub-topic`,
  editLmsSubtopic: () => `${manageLmsVersion}/sub-topic`,
  deleteLmsVideo: (data: LMSDeleteVideoModel) =>
    `${manageLmsVersion}/video/${data.videoId}?title=${data.title}&language=${data.language}&topicOrSubTopicId=${data.id}`,
  deleteLmsSubtoopic: (id: string) => `${manageLmsVersion}/sub-topic/${id}`,
  getTicTacQuiz: (id: string) => `${manageLmsVersion}/mcqTttQuestions/${id}`,
  feedback: {
    getLmsFeedbackByModuleId: (moduleId: string) =>
      `${manageLmsVersion}/feedback/module/${moduleId}`,
    postLmsFeedback: () => `${manageLmsVersion}/feedback`,
    updateLmsFeedback: (moduleId: string) => `${manageLmsVersion}/feedback/${moduleId}`
  },
  pts: {
    lmsPtsTopic: () => `${manageLmsVersion}/pts-topic`,
    lmsPtsTopicId: (topicId: string) => `${manageLmsVersion}/pts-topic/${topicId}`,
    getLmsPtsModuleData: (moduleId: string) => `${manageLmsVersion}/pts-topic/module/${moduleId}`,
    lmsPtsCheckList: () => `${manageLmsVersion}/pts-checklist`,
    lmsPtsCheckListId: (checkListId: string) => `${manageLmsVersion}/pts-checklist/${checkListId}`
  },
  assessments: {
    lmsAssessmentsTopic: () => `${manageLmsVersion}/assessment-practical-topic`,
    lmsAssessmentsTopicId: (topicId: string) =>
      `${manageLmsVersion}/assessment-practical-topic/${topicId}`,
    getLmsAssessmentsPracticalData: (moduleId: string) =>
      `${manageLmsVersion}/assessment-practical-topic/module/${moduleId}`,
    lmsAssessmentsCheckList: () => `${manageLmsVersion}/assessment-practical-checklist`,
    lmsAssessmentsCheckListId: (checkListId: string) =>
      `${manageLmsVersion}/assessment-practical-checklist/${checkListId}`,
    lmsAssessmentTheory: (moduleId: string) => `${manageLmsVersion}/assessment-theory/${moduleId}`
  },
  lmsMtfDnd: () => `${manageLmsVersion}/mtf-dnd`,
  lmsMtfDndId: (quizId: string) => `${manageLmsVersion}/mtf-dnd/${quizId}`,
  lmsImg: () => `${manageLmsVersion}/image`,
  lmsGetCertificate: (moduleId: string) => `${manageLmsVersion}/certificate/module/${moduleId}`,
  lmsPostCertificate: () => `${manageLmsVersion}/certificate`,
  deleteMcqTtt: (id: string) => `${manageLmsVersion}/mcqTttQuestions/${id}`,
  deleteMtfDnd: (id: string) => `${manageLmsVersion}/mtf-dnd/${id}`,
  updateMcqTtt: () => `${manageLmsVersion}/mcqTttQuestions`,
  updateMtfDnd: () => `${manageLmsVersion}/updateMtfDnd`,
  publishLmsModule: (moduleId: string) => `${manageLmsVersion}/publish/module/${moduleId}`,
  getVideoData: (data: { count: number; duration: number }) =>
    `${manageLmsVersion}/video/count-and-duration?language=EN`,
  getDashboardModules: (data: { moduleType: string; moduleState: string }) =>
    `${manageLmsVersion}/module/dashboard?moduleState=${data.moduleState}&moduleType=${data.moduleType}&language=EN`,
  getDashboardCategories: (data: { moduleId: string }) =>
    `${manageLmsVersion}/module/${data.moduleId}/categories`,
  getAllStoresListLms: (data: { pageNo: number; pageSize: number; employeeId: String }) =>
    `${storeApiVersion}/store/assign-stores?pageNo=${data.pageNo}&pageSize=${data.pageSize}&employeeId=${data.employeeId}`
};

export const practicalAssessmentEndPoints = {
  getAllModules: () => `${manageLmsVersion}/user-practical-assessment-module-mapping`,
  getAllTopicsAndCheckList: (empId: number, moduleId: string) =>
    `${manageLmsVersion}/user-practical-assessment-checklist-mapping/employee/${empId}/module/${moduleId}`,
  postCheckList: () => `${manageLmsVersion}/user-practical-assessment-checklist-mapping`,
  postImages: (param: any) =>
    `${manageLmsVersion}/user-practical-assessment-image-mapping?employeeId=${param.employeeId}&topicId=${param.topicId}`,
  deleteImages: (id: string) => `${manageLmsVersion}/user-practical-assessment-image-mapping`,
  postModules: () => `${manageLmsVersion}/user-practical-assessment-module-mapping`
};

export const mobileEndPoints = {
  lms: {
    getTotalEarnedPoints: (employeeId: string) =>
      `${manageLmsVersion}/dashboard/earned-points?employeeId=${employeeId}`,
    getModulesList: (
      storeCode: number,
      fromAssignedLearning: boolean,
      roleName: string,
      employeeId: string,
      language: string
    ) =>
      storeCode
        ? `${manageLmsVersion}/employee/${employeeId}/assigned-learning?storeCode=${storeCode}&fromAssignedLearning=${fromAssignedLearning}&roleName=${roleName}&language=${language}`
        : `${manageLmsVersion}/employee/${employeeId}/assigned-learning?fromAssignedLearning=${fromAssignedLearning}&roleName=${roleName}&language=${language}`,
    getCertificateCarousel: () => `${manageLmsVersion}/dashboard/carousel`,
    getTopicSubtopic: (categoryId: string, empId: string, language: string) =>
      `${manageLmsVersion}/employee/${empId}/category/${categoryId}/topics?language=${language}`,
    getCategories: (moduleId: string, employeeId: string, language: string) =>
      `${manageLmsVersion}/employee/${employeeId}/module/${moduleId}/categories?language=${language}`,
    getQuizDetails: (
      quizId: string,
      employeeId: string,
      quizType: string,
      categoryId: string,
      topicId: string
    ) =>
      `${manageLmsVersion}/category/${categoryId}/topic/${topicId}/quiz/${quizId}?employeeId=${employeeId}&quizType=${quizType}`,
    updateVideoStatus: (
      videoId: string,
      employeeId: string,
      topicId: string,
      categoryId: string,
      language: string
    ) =>
      `${manageLmsVersion}/employee/${employeeId}/video/${videoId}?topicId=${topicId}&categoryId=${categoryId}&language=${language}`,
    getDaysLeft: (employeeId: string) =>
      `${manageLmsVersion}/dashboard/days-left?employeeId=${employeeId}`,
    updateTopicVideoStatus: (
      videoId: string,
      employeeId: string,
      topicId: string,
      categoryId: string
    ) =>
      `${manageLmsVersion}/employee/${employeeId}/video/${videoId}?topicId=${topicId}&categoryId=${categoryId}`,
    updateSubTopicVideoStatus: (
      videoId: string,
      employeeId: string,
      topicId: string,
      categoryId: string,
      subtopicId: string,
      language: string
    ) =>
      `${manageLmsVersion}/employee/${employeeId}/video/${videoId}?topicId=${topicId}&categoryId=${categoryId}&subtopicId=${subtopicId}&language=${language}`,
    postQuizResponse: (
      quizId: string,
      employeeId: string,
      quizType: string,
      categoryId: string,
      topicId: string,
      responseId: string,
      subTopicId: string
    ) =>
      subTopicId
        ? `${manageLmsVersion}/category/${categoryId}/topic/${topicId}/quiz/${quizId}?employeeId=${employeeId}&quizType=${quizType}&responseId=${responseId}&subTopicId=${subTopicId}`
        : `${manageLmsVersion}/category/${categoryId}/topic/${topicId}/quiz/${quizId}?employeeId=${employeeId}&quizType=${quizType}&responseId=${responseId}`,
    getFaqs: () => `${userLmsVersion}/faq`,
    getFaqSearchResult: (searchText: string, pageNo: number) =>
      `${userLmsVersion}/question/search?searchText=${searchText}&pageNo=${pageNo}&pageSize=6`,
    getEnquiries: (employeeId: string, status: string) =>
      `${userLmsVersion}/question-answer/employee/${employeeId}?status=${status}`,
    postQuestion: (employeeId: string) =>
      `${userLmsVersion}/question-answer/employee/${employeeId}`,
    postMcqTttQuiz: (
      quizId: string,
      employeeId: string,
      quizType: string,
      categoryId: string,
      topicId: string,
      responseId: string,
      subTopicId: string,
      questionId: string
    ) =>
      subTopicId
        ? `${manageLmsVersion}/category/${categoryId}/topic/${topicId}/mcq-ttt-quiz/${quizId}?employeeId=${employeeId}&quizType=${quizType}&responseId=${responseId}&questionId=${questionId}&subTopicId=${subTopicId}`
        : `${manageLmsVersion}/category/${categoryId}/topic/${topicId}/mcq-ttt-quiz/${quizId}?employeeId=${employeeId}&quizType=${quizType}&responseId=${responseId}&questionId=${questionId}`,
    postMtfDndQuiz: (quizId: string, categoryId: string, topicId: string) =>
      `${manageLmsVersion}/category/${categoryId}/topic/${topicId}/mtf-dnd-quiz/${quizId}`,
    postTicTacToeQuizResponse: (
      publishQuizId: string,
      employeeId: string,
      quizType: string,
      categoryId: string,
      topicId: string,
      responseId: string,
      subTopicId: string,
      questionId: string
    ) =>
      subTopicId
        ? `${manageLmsVersion}/category/${categoryId}/topic/${topicId}/mcq-ttt-quiz/${publishQuizId}?employeeId=${employeeId}&quizType=${quizType}&responseId=${responseId}&subTopicId=${subTopicId}&questionId=${questionId}`
        : `${manageLmsVersion}/category/${categoryId}/topic/${topicId}/mcq-ttt-quiz/${publishQuizId}?employeeId=${employeeId}&quizType=${quizType}&responseId=${responseId}&questionId=${questionId}`,
    getNewsList: (filter: string) => `${manageNewsApiVersion}/published-title?filter=${filter}`,
    getNewsDetails: (newsId: string) => `${manageNewsApiVersion}/published-title/${newsId}`,
    getLearningPathDetails: (
      employeeId: string,
      storeCode: number,
      roleName: string,
      moduleType: string
    ) =>
      `${manageLmsVersion}/learning-path?employeeId=${employeeId}&roleName=${roleName}&moduleType=${moduleType}${
        storeCode ? `&storeCode=${storeCode}` : ""
      }`,
    getStatistics: (employeeId: string) => `${manageLmsVersion}/statistics/employee/${employeeId}`,
    getSpecialModules: (employeeId: string) =>
      `${manageLmsVersion}/statistics/employee/${employeeId}/special-training`,
    getModuleDetail: (
      employeeId: string,
      publishModuleName: string,
      status: string,
      publishModuleId: string
    ) =>
      `${manageLmsVersion}/statistics/details?publishModuleName=${publishModuleName}&status=${status}&publishModuleId=${publishModuleId}&employeeId=${employeeId}`
  },
  getContinueLearning: () => `${manageLmsVersion}/continue-learning`,
  getEvents: (startDate: string, endDate: string) =>
    `${calendarVersion}/user-event/${startDate}/${endDate}`,
  getDayEvents: (date: string) => `${calendarVersion}/user-event/date/${date}`,
  getEventById: (eventId: string) => `${calendarVersion}/user-event/${eventId}`,
  submitMtfDnd: (publishQuizId: string, categoryId: string, topicId: string) =>
    `${manageLmsVersion}/category/${categoryId}/topic/${topicId}/mtf-dnd-quiz/${publishQuizId}`,

  getAssessmentQuestions: (moduleId: string, employeeId: string) =>
    `${manageLmsVersion}/assessment-theoretical/publish-module/${moduleId}?employeeId=${employeeId}`,
  submitAssessment: (quizId: string) => `${manageLmsVersion}/assessment-theoretical/${quizId}`,
  exitAssessment: (employeeId: string, moduleId: string) =>
    `${manageLmsVersion}/assessment-theoretical/publish-module/${moduleId}/clear?employeeId=${employeeId}`,
  deleteAssessment: (employeeId: string, moduleId: string) =>
    `${manageLmsVersion}/user-pts-module-mapping?employeeId=${employeeId}&moduleId=${moduleId}`,
  lmsPTS: {
    getPTSData: (employeeId: string, moduleId: string) =>
      `${manageLmsVersion}/user-pts-checklist-mapping/employee/${employeeId}/module/${moduleId}`,
    ptsCheckListSelect: () => `${manageLmsVersion}/user-pts-checklist-mapping`,
    ptsCheckListUnSelect: (employeeId: string, publishChecklistId: string) =>
      `${manageLmsVersion}/user-pts-checklist-mapping/${employeeId}/${publishChecklistId}`
  },
  getAssessmentResult: (moduleId: string) =>
    `${manageLmsVersion}/assessment-theoretical/publish-module/${moduleId}/generate-result`,
  getLeaderboardList: () => `${manageLmsVersion}/leader-board/by-countryCodes`,
  getTimbitCollection: () => `${manageLmsVersion}/leader-board/employee`,
  getUserNotifications: (employeeId: string) =>
    `${manageNotification}/in-app/notification?employeeId=${employeeId}`,
  saveUserFCMToken: () => `${manageNotification}/employee/token-details`,
  editUserProfile: () => `${iamVersion}/user/profile`
};

export const lmsReportsEndPoints = {
  getDetailedReport: () => `${manageLmsVersion}/user-report/by-employee-id`,
  getReportsTableDetails: () => `${manageLmsVersion}/user-report/by-role`,
  generateReportBuilder: (
    reportType: string,
    allSelected: boolean,
    pageNum: number,
    dataType: string,
    modulesAllSelected: boolean,
    moduleType: string,
    moduleSearchKeyword: string
  ) =>
    `${manageLmsVersion}/report-builder?pageNo=${pageNum}&pageSize=10&reportType=${reportType}&dataType=${dataType}&allSelected=${allSelected}&modulesAllSelected=${modulesAllSelected}&moduleType=${moduleType}&moduleSearchKeyword=${moduleSearchKeyword}`
};
export const detailedLmsReportsEndPoints = {
  getAllModules: () => `${manageLmsVersion}/report-builder/modules`
};
export const manageNewsEndPoints = {
  getNewsList: () => `${manageNewsApiVersion}/title`,
  deleteNews: (titleId: string) => `${manageNewsApiVersion}/title/${titleId}`,
  getNewsDetails: (newsId: string) => `${manageNewsApiVersion}/title/${newsId}`,
  getCategoryList: () => `${manageNewsApiVersion}/category-list`,
  postCreateNewNews: () => `${manageNewsApiVersion}/title`,
  getMediaLink: () => `${manageNewsApiVersion}/feature-media/link`,
  createMedia: () => `${manageNewsApiVersion}/feature-media`,
  saveNewsDetails: (titleId: string) => `${manageNewsApiVersion}/title/${titleId}/save/all`,
  imgPublicUrl: () => `${manageNewsApiVersion}/feature-media/generate-public-url`,
  publishNews: () => `${manageNewsApiVersion}/publish`
};
export const manageAskEndPoints = {
  getQuestionsList: () => `${manageAskTell}/question`,
  getSTQuestionsList: () => `${manageAskTell}/support`,
  getQuestionCount: () => `${manageAskTell}/question/count`,
  answerReply: () => `${manageAskTell}/answer`,
  deleteQuestion: (questionId: string) => `${manageAskTell}/question/${questionId}`,
  faq: {
    getAllFaqs: () => `${manageAskTell}/faq`,
    updateFaq: (id: string) => `${manageAskTell}/faq/${id}`,
    createFaq: () => `${manageAskTell}/faq`,
    deleteFaq: (id: string) => `${manageAskTell}/faq/${id}`
  }
};
export const manageExistingUserEndPoints = {
  existingUsers: () => `${manageLmsVersion}/on-board`
};
export const homeEndPoints = {
  getVideos: () => `${manageLmsVersion}/dashboard/homepage-items`
};
export const calendarEndPoints = {
  getGccCountries: () => `${storeApiVersion}/country/gcc`,
  createEvent: () => `${calendarVersion}/event`,
  getAllEvents: (startDate: string, endDate: string) =>
    `${calendarVersion}/event/${startDate}/${endDate}`,
  getAllAreaManager: (role: string) => `${iamVersion}/user/role/${role}/countries`,
  getStores: () => `${storeApiVersion}/store/country/by-codes`,
  getEmployees: () => `${iamVersion}/user/internal/countries`,
  deleteEvent: (eventId: string) => `${calendarVersion}/event/${eventId}`,
  updateEvent: (eventId: string) => `${calendarVersion}/event/${eventId}`,
  getEventById: (eventId: string) => `${calendarVersion}/event/${eventId}`
};
