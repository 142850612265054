import { JsonObject, JsonProperty } from "json2typescript";
@JsonObject("LpDetailsDataModel")
export class LpDetailsDataModel {
  @JsonProperty("store", String)
  store: string = "";
  @JsonProperty("auditor", String)
  auditor: string = "";
  @JsonProperty("auditStartedDate", String)
  auditStartedDate: string = "";
  @JsonProperty("auditEndedDate", String)
  auditEndedDate: string = "";
  @JsonProperty("startTime", String)
  startTime: string = "";
  @JsonProperty("endTime", String)
  endTime: string = "";
  @JsonProperty("signedBy", String)
  signedBy: string = "";
}

export class LpObservationsModel {
  @JsonProperty("observation", String)
  observation: string = "";
  @JsonProperty("observationId", Number)
  observationId: number = 0;
}

@JsonObject("LpImagesModel")
export class LpImagesModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("signedUrl", String)
  signedUrl: string = "";
}
@JsonObject("LpOpportunityModel")
export class LpOpportunityModel {
  @JsonProperty("categoryId", Number)
  categoryId: number = 0;
  @JsonProperty("subCategoryId", Number)
  subCategoryId: number = 0;
  @JsonProperty("serialNumber", String)
  serialNumber: string = "";
  @JsonProperty("questionLabel", String)
  questionLabel: string = "";
  @JsonProperty("question", String)
  question: string = "";
  @JsonProperty("comment", String)
  comment: string = "";
  @JsonProperty("images", Array)
  images: LpImagesModel[] = [];
  @JsonProperty("observations", Array)
  observations: LpObservationsModel[];
}
@JsonObject("LpCategoriesNameModel")
export class LpCategoriesNameModel {
  @JsonProperty("subCategoryLabel", String)
  subCategoryLabel: string = "";
  @JsonProperty("subCategoryName", String)
  subCategoryName: string = "";
  @JsonProperty("questions", Array)
  questions: LpOpportunityModel[] = [];
}
export class LpOpportunityDetailsModal {
  @JsonProperty("rejectedData", [LpCategoriesNameModel])
  rejectedData: LpCategoriesNameModel[] = null;
}
export class CategoryListDataModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("title", String)
  title: string = "";
  @JsonProperty("earnedValue", Number)
  earnedValue: number = 0;
  @JsonProperty("availableValue", Number)
  availableValue: number = 0;
  @JsonProperty("overallValue", String)
  overallValue: string = "0";
  @JsonProperty("status", String)
  status: string = "0";
}
@JsonObject("CategoryTableDataModel")
export class CategoryTableDataModel {
  @JsonProperty("CategoryList", [CategoryListDataModel])
  CategoryList: CategoryListDataModel[];
  @JsonProperty("totalEarnedValue", String)
  totalEarnedValue: string = "";
  @JsonProperty("totalAvailableValue", String)
  totalAvailableValue: string = "";
  @JsonProperty("overAllPercentage", String)
  overAllPercentage: string = "";
}
@JsonObject("LpReportsDataModal")
export class LpReportsDataModal {
  @JsonProperty("location", String)
  location: string = "";
  @JsonProperty("Details", LpDetailsDataModel)
  Details: LpDetailsDataModel;
  @JsonProperty("resultPercentage", Number)
  resultPercentage: number = 0;
  @JsonProperty("resultStatus", String)
  resultStatus: string = "";
  @JsonProperty("grade", String)
  grade: string = "";
  @JsonProperty("CategoryTableData", [CategoryTableDataModel])
  CategoryTableData: CategoryTableDataModel;
  @JsonProperty("FaildCategoryTableData", [])
  FaildCategoryTableData:[];
  @JsonProperty("OpportunityDetails", [LpOpportunityDetailsModal])
  OpportunityDetails: LpOpportunityDetailsModal;
  @JsonProperty("isSigned", Boolean)
  isSigned: boolean = false;
  @JsonProperty("auditType", String)
  auditType: string = "";
  @JsonProperty("signedUrl", String && null)
  externalPDFlink: string = "";
}
@JsonObject("AckDropDownModel")
export class AckDropDownModel {
  @JsonProperty("name", String)
  value?: string = "";
  @JsonProperty("name", String)
  label?: string = "";
  @JsonProperty("employeeId", String)
  employeeId?: string = "";
}

@JsonObject("LpAcknowledgementModel")
export class LpAcknowledgementModel {
  @JsonProperty("dropDownData", AckDropDownModel)
  dropDownData: AckDropDownModel[];
}
@JsonObject("LpPostAcknowledgementModel")
export class LpPostAcknowledgementModel {
  @JsonProperty("file", String)
  file: string = "";
  @JsonProperty("reportId", Number)
  reportId: number = null;
  @JsonProperty("storeInChargeId", String)
  storeInChargeId: string = "";
  @JsonProperty("remarks", String)
  remarks: string = "";
  @JsonProperty("endDate", String)
  endDate: Date = new Date();
}
@JsonObject("LpReportTableModel")
export class LpReportTableModel {
  @JsonProperty("storeCode", Number)
  storeId: number = 0;
  @JsonProperty("storeName", String)
  storeName: string = "";
  @JsonProperty("auditDate", String)
  auditedOn: Date = new Date();
  @JsonProperty("auditor", String && null)
  auditBy: string = "";
  @JsonProperty("visitType", String && null)
  visitType: string = "";
  @JsonProperty("overallResult", Number && null)
  overAllScore: number = 0;
  @JsonProperty("overallKey", String && null)
  grade: string = "";
  @JsonProperty("auditId", Number)
  auditId: number = 0;
  @JsonProperty("reportId", Number)
  reportId: number = 0;
  @JsonProperty("countOfCriticalPoints", Number && null)
  noOfCriticalPoints: number = 0;
}

@JsonObject("LpReportTableResponseModel")
export class LpReportTableResponseModel {
  @JsonProperty("totalRecords", Number)
  totalRecords: number = 0;
  @JsonProperty("records", [LpReportTableModel])
  records: LpReportTableModel[] = null;
}

@JsonObject("LpActionPlansGraphData")
export class LpActionPlansGraphData {
  @JsonProperty("series", Array)
  series: number[] = [];
  @JsonProperty("labels", Array)
  labels: string[] = [];
  @JsonProperty("totalReports", Number)
  totalReports: number = 0;
  @JsonProperty("inProcessReport", Number)
  inProcessReport: number = 0;
}

@JsonObject("LpCritcalPointsReportData")
export class LpCritcalPointsReportData {
  @JsonProperty("averageDeductedPoints", Array)
  averageDeductedPoints: number[] = [];
  @JsonProperty("labels", Array)
  labels: string[] = [];
  @JsonProperty("shortLabel", Array)
  shortLabel: string[] = [];
}

@JsonObject("LpCompleteAverageScoreDataModel")
export class LpCompleteAverageScoreDataModel {
  @JsonProperty("name", Array)
  name: string[] = [];
  @JsonProperty("percentage", Array)
  percentage: number[] = [];
}

@JsonObject("LpMonthOnMonthAverageDataModel")
export class LpMonthOnMonthAverageDataModel {
  @JsonProperty("percentage", Array)
  percentage: number[] = [];
  @JsonProperty("month", Array)
  month: string[] = [];
}

export class BrandStandardsGraphDataModel {
  series: number[] = [];
  categories: string[] = [];
  averageScore: number = 0;
}

@JsonObject("LpStoreAccessSummaryDataModel")
export class LpStoreAccessSummaryDataModel {
  @JsonProperty("actionPlansData", LpActionPlansGraphData)
  actionPlansData: LpActionPlansGraphData = null;
  @JsonProperty("completeAverageScore", LpCompleteAverageScoreDataModel)
  completeAverageScore: LpCompleteAverageScoreDataModel = null;
  @JsonProperty("monthOnMonthAverageData", LpMonthOnMonthAverageDataModel)
  monthOnMonthAverageData: LpMonthOnMonthAverageDataModel = null;
  @JsonProperty("criticalPointsReport", LpCritcalPointsReportData)
  criticalPointsReport: LpCritcalPointsReportData = null;
  @JsonProperty("brandStandardAverageReport", BrandStandardsGraphDataModel)
  brandStandardAverageReport: BrandStandardsGraphDataModel = null;
}
@JsonObject("LpKPIDataModel")
export class LpKPIDataModel {
  @JsonProperty("id", Number)
  id: Number = 0;
  @JsonProperty("label", String)
  label?: string = "";
  @JsonProperty("assetManagementScore", Number && null, true)
  assetManagementScore?: number = 0;
  @JsonProperty("healthSafetyScore", Number && null, true)
  healthSafetyScore?: number = 0;
  @JsonProperty("inventoryScore", Number && null, true)
  inventoryScore?: number = 0;
  @JsonProperty("municipalityRequirementsScore", Number && null, true)
  municipalityRequirementsScore?: number = 0;
  @JsonProperty("averageScore", Number && null, true)
  averageScore?: number = 0;
  @JsonProperty("pettyCashScore", Number && null, true)
  pettyCashScore?: number = 0;
  @JsonProperty("salesReportsScore", Number && null, true)
  salesReportsScore?: number = 0;
  @JsonProperty("tillsScore", Number && null, true)
  tillsScore?: number = 0;
  @JsonProperty("voidsDiscountsScore", Number && null, true)
  voidsDiscountsScore?: number = 0;
  @JsonProperty("wastageScore", Number && null, true)
  wastageScore?: number = 0;
  @JsonProperty("overallScore", Number && null, true)
  overallScore?: number = 0;
  @JsonProperty("grade", String && null, true)
  grade?: string = "";
  @JsonProperty("cashHandlingScore", Number && null, true)
  cashHandlingScore?: number = 0;
  @JsonProperty("safeVaultScore", Number && null, true)
  safeVaultScore?: number = 0;
  @JsonProperty("expiryScore", Number && null, true)
  expiryScore?: number = 0;
  @JsonProperty("bankingScore", Number && null, true)
  bankingScore?: number = 0;
}

@JsonObject("LpGradingDataModel")
export class LpGradingDataModel {
  @JsonProperty("id", Number)
  id: Number = 0;
  @JsonProperty("label", String)
  label?: string = "";
  @JsonProperty("percentage", Number)
  percentage: number = 0;
  @JsonProperty("totalStores", Number)
  totalStores: number = 0;
  @JsonProperty("presentGrade", Number)
  presentGrade: number = 0;
  @JsonProperty("previousGrade", Number)
  previousGrade: number = 0;
  @JsonProperty("a", Number)
  a: Number = 0;
  @JsonProperty("b", Number)
  b: Number = 0;
  @JsonProperty("c", Number)
  c: Number = 0;
  @JsonProperty("f", Number)
  f: Number = 0;
}

@JsonObject("LpStoreRankingsTableData")
export class LpStoreRankingsTableData {
  @JsonProperty("rank", Number)
  rank: number = 0;
  @JsonProperty("storeName", String)
  storeName: string = "";
  @JsonProperty("country", String)
  country?: string = "";
  @JsonProperty("grade", String)
  grade: string = "";
  @JsonProperty("avgScore", Number)
  avgScore: number = 0;
  @JsonProperty("criticalPoints", Number)
  criticalPoints?: number = 0;
  @JsonProperty("overallScore", Number)
  overallScore?: number = 0;
}
@JsonObject("LpStoreRankingDataModel")
export class LpStoreRankingDataModel {
  @JsonProperty("content", [LpStoreRankingsTableData])
  content: LpStoreRankingsTableData[] = null;
  @JsonProperty("totalElements", Number)
  totalElements: number = 0;
}

@JsonObject("LpReportsSummaryOpportunityDataModel")
export class LpReportsSummaryOpportunityDataModel {
  @JsonProperty("categoryName", String)
  categoryName: string = "";
  @JsonProperty("label", String)
  questionLabel: string = "";
  @JsonProperty("name", String)
  question: string = "";
  @JsonProperty("points", Number)
  points: number = 0;
  @JsonProperty("frequency", Number)
  frequency: number = 0;
}
@JsonObject("LpGradeDistributionModel")
export class LpGradeDistributionModel {
  @JsonProperty("label", Array)
  label: string[] = [];
  @JsonProperty("storeCount", Array)
  storeCount: number[] = [];
}

@JsonObject("LpIndividualCritcalPointsReportModel")
export class LpIndividualCritcalPointsReportModel {
  @JsonProperty("averageDeductedPoints", Array)
  averageDeductedPoints: number[] = [];
  @JsonProperty("labels", Array)
  labels: string[] = [];
  @JsonProperty("shortLabel", Array)
  shortLabel: string[] = [];
}

@JsonObject("LpIndividualCriticalCategoriesModel")
export class LpIndividualCriticalCategoriesModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("storeName", String)
  storeName: string = "";
  @JsonProperty("overallScore", Number)
  overallScore: number = 0;
  @JsonProperty("bankingScore", Number)
  bankingScore: number = 0;
  @JsonProperty("cashHandlingScore", Number)
  cashHandlingScore: number = 0;
  @JsonProperty("expiryScore", Number)
  expiryScore: number = 0;
  @JsonProperty("safeVaultScore", Number)
  safeVaultScore: number = 0;
}

export class LpIndividualAccessSummaryDataModel {
  actionPlansData: LpActionPlansGraphData = null;
  averageScore: LpCompleteAverageScoreDataModel = null;
  criticalPointsReport: LpIndividualCritcalPointsReportModel = null;
  criticalCategories: LpIndividualCriticalCategoriesModel[] = null;
  gradeDistribution: LpGradeDistributionModel = null;
  monthOnMonthAverageData: LpMonthOnMonthAverageDataModel = null;
  opportunityData: LpReportsSummaryOpportunityDataModel[] = null;
  lpKPIData: LpKPIDataModel[] = null;
  areaManagerRankingsTableData?: LpStoreRankingsTableData[] = null;
  storeRankingTableData?: LpStoreRankingDataModel = null;
}
export class LPReportBuilderResponseModel {
  metaData: LPReportBuilderMetaData = null;
  apiResponse: {
    content: LpKPIDataModel[];
    totalElements: number;
  };
}

export class LPGradingReportBuilderResponseModel {
  metaData: LPReportBuilderMetaData = null;
  apiResponse: {
    content: LpGradingDataModel[];
    totalElements: number;
  };
}

export class LPReportBuilderMetaData {
  startDate: string = "";
  endDate: string = "";
  reportType: string = "";
  levelOfReport: string = "";
  countryLabel: string = "";
}

@JsonObject("KPIDataModel")
export class KPIDataModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("label", String)
  value: string = "";
  @JsonProperty("name", String)
  label: string = "";
}

@JsonObject("RevKPIElementModel")
export class RevKPIElementModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("label", String)
  value: string = "";
  @JsonProperty("value", String)
  label: string = "";
}

@JsonObject("RevKPIDataModel")
export class RevKPIDataModel {
  @JsonProperty("id", String && null)
  id: string = "";
  @JsonProperty("label", String)
  value: string = "";
  @JsonProperty("value", String)
  label: string = "";
  @JsonProperty("childElements", [RevKPIElementModel] && null)
  childElements: RevKPIElementModel[] = [];
}

@JsonObject("LPAllStoresDataModel")
export class LPAllStoresDataModel {
  @JsonProperty("name", String)
  value: string = "";
  @JsonProperty("code", Number)
  code?: number = 0;
  @JsonProperty("name", String)
  label: string = "";
}
@JsonObject("AllAreaManagersDataModel")
export class AllAreaManagersDataModel {
  @JsonProperty("roleId", String)
  id?: string = "";
  @JsonProperty("name", String)
  value: string = "";
  @JsonProperty("name", String)
  label: string = "";
  @JsonProperty("employeeId", String && null)
  code: string = "";
  @JsonProperty("roleName", String)
  roleName: string = "";
}
@JsonObject("AllAreaManagersListDataModel")
export class AllAreaManagersListDataModel {
  @JsonProperty("content", [AllAreaManagersDataModel])
  content: AllAreaManagersDataModel[] = null;
  @JsonProperty("totalElements", Number)
  totalElements: number = 0;
  @JsonProperty("currentPage", Number, true)
  currentPage?: number = 0;
}

@JsonObject("OpsManagerModel")
export class OpsManagerModel {
  @JsonProperty("roleId", String, true)
  id?: string = "";
  @JsonProperty("lastName", String)
  value: string = "";
  @JsonProperty("firstName", String)
  label: string = "";
  @JsonProperty("employeeId", String && null)
  employeeId: string = "";
  @JsonProperty("firstName", String)
  roleName: string = "";
}
@JsonObject("OpsManagerDataModel")
export class OpsManagerDataModel {
  @JsonProperty("content", [OpsManagerModel])
  content: OpsManagerModel[] = null;
  @JsonProperty("totalElements", Number)
  totalElements: number = 0;
  @JsonProperty("currentPage", Number, true)
  currentPage?: number = 0;
}

@JsonObject("AllLPAuditorDataModel")
export class AllLPAuditorDataModel {
  @JsonProperty("roleId", String)
  id?: string = "";
  @JsonProperty("name", String)
  value: string = "";
  @JsonProperty("name", String)
  label: string = "";
  @JsonProperty("employeeId", String)
  code: string = "";
  @JsonProperty("roleName", String)
  roleName: string = "";
}
@JsonObject("AllLPAuditorListDataModel")
export class AllLPAuditorListDataModel {
  @JsonProperty("content", [AllLPAuditorDataModel])
  content: AllLPAuditorDataModel[] = null;
  @JsonProperty("totalElements", Number)
  totalElements: number = 0;
  @JsonProperty("currentPage", Number, true)
  currentPage?: number = 0;
}
